<template>
  <div v-if="showModal && isWallet && clientSecret && bookingToken" id="wallet-details" class="modal show d-block">
    <div class="modal-dialog text-center modal-vertical-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click="close">×</button>

          <span class="modal-title" id="label-please-wait">
            <h1>{{ $t('book_now_guarantee_your_spot') }}</h1>
            <h2>{{ $t('payment_through_safe_connection') }}</h2>
          </span>
        </div>

        <div class="modal-body">
          <div class="stripe-card">
            <span>{{loading ? $t('processing_payment') : message }}</span>
          </div>

          <div id="payment-request-button">
            <!-- Stripe Payment Request Button will be mounted here -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useProductStore } from '@/stores/productStore';
import { useCheckoutStore } from '@/stores/checkoutStore';

const props = defineProps<{
  clientSecret: string,
  bookingToken: string,
}>();

const emit = defineEmits(['closeWalletDetails', 'paymentSuccess', 'paymentError']);

const stripe = ref<Stripe | null>(null);
const showModal = ref(true);
const loading = ref(false);
const i18n = useI18n();

const { amountToPayInCents, isWallet } = storeToRefs(useCheckoutStore());
const { productInfo } = storeToRefs(useProductStore());

const message = ref(i18n.t('please_click_below_to_complete_your_booking'));

const close = () => {
  showModal.value = false;
  emit('closeWalletDetails');
};

const initializeStripe = async () => {
  try {
    stripe.value = await loadStripe(import.meta.env.VITE_STRIPE_KEY);
    if (!stripe.value) {
      throw new Error('Failed to load Stripe');
    }

    // Create payment request
    const paymentRequest = stripe.value.paymentRequest({
      country: 'ES',
      currency: productInfo.value?.currency_code.toLowerCase() || 'eur',
      total: {
        label: productInfo.value?.title ?? '',
        amount: amountToPayInCents.value,
      },
      requestPayerName: false,
      requestPayerEmail: false,
      requestShipping: false,
    });

    // Check if Google Pay is available
    const result = await paymentRequest.canMakePayment();
    if (!result) {
      message.value = i18n.t('mobile_payment_not_available');
      return;
    }

    // Create and mount the payment request button
    const elements = stripe.value.elements();
    const prButton = elements.create('paymentRequestButton', {
      paymentRequest,
      style: {
        paymentRequestButton: {
          type: 'default',
          theme: 'dark',
          height: '48px',
        },
      },
    });

    // Mount the button
    prButton.mount('#payment-request-button');

    // Handle payment success
    paymentRequest.on('paymentmethod', async (e) => {
      try {
        loading.value = true;
        message.value = i18n.t('processing_payment');

        // Confirm the payment with Stripe
        const { paymentIntent: paymentIntentResult, error: confirmError } = await stripe.value!.confirmCardPayment(
          props.clientSecret,
          {
            payment_method: e.paymentMethod.id,
          },
          { handleActions: false }
        );

        if (confirmError) {
          e.complete('fail');
          throw confirmError;
        }

        if (paymentIntentResult.status === 'requires_action') {
          console.error("PaymentIntent requires further action", paymentIntentResult);
        }

        e.complete('success');
        emit('paymentSuccess');

      } catch (error) {
        console.error('Payment failed:', error);
        emit('paymentError', error instanceof Error ? error.message : 'Payment failed');
      } finally {
        loading.value = false;
      }
    });

  } catch (error) {
    console.error('Failed to initialize Stripe:', error);
    message.value = i18n.t('failed_to_initialize_payment');
    emit('paymentError', error instanceof Error ? error.message : 'Failed to initialize payment');
  }
};

watch(() => props.clientSecret, async () => {
  if (props.clientSecret) {
    await initializeStripe();
  }
});
</script>

<style scoped>
h1 {
  color: #000;
  font-size: 17px;
  font-weight: 700;
  height: 22px;
}

h2 {
  color: #5b5b65;
  font-size: 13px;
  font-weight: 400;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-header {
  background-image: -webkit-linear-gradient(#f5f5f7, #e9e9ea);
  border-radius: 6px 6px 0 0;
  display: block;
}

.modal-dialog {
  max-width: 400px;
}

#payment-request-button {
  margin: 20px 0;
  min-height: 48px;
}

.close {
  position: absolute;
  right: 10px;
  margin-top: -2px;
  opacity: 0.8;
  color: #252525;
  border: none;
}
</style>